import React from "react";
import { Route, Switch } from "react-router-dom";
import MainController from "view/main";
import SiteController from "./site";
import { pages } from "links";

function StoreController() {
  return (
    <Switch>
      <Route path={pages.GUEST} component={SiteController} />
      <Route path="*" component={MainController} />
    </Switch>
  );
}

export default StoreController;
