
// Soft UI Dashboard React example components
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { pages } from "links/pages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DiscountForm from "./_form";
// Custom styles for the Tables

// Service
import { updateDiscount, getDiscountDetail } from "../service";
import { getProductDetail } from "view/main/products/service";
import schema,{defaultValues} from "../schema";

export default function DiscountUpdate() {
    const { enqueueSnackbar } = useSnackbar();
    const { id, pid } = useParams();
    const history = useHistory();

    async function loadData() {

        try {
            const productRes = await getProductDetail(pid);
            const disountRes = await getDiscountDetail(id);
            if (disountRes.flag) {
                const { rate, label, min_qty } = disountRes?.data?.data?.discount;
                reset({
                    rate,
                    label,
                    min_qty,
                    product: productRes.data.data.product
                }, {
                    keepDirty: true
                });
            }  
        } catch(err) {

        }
  
    }


    useEffect(() => {
        // if (Object.keys(itemData).length === 0 && id !== undefined) {
        //     loadData();
        // }
        loadData();
        // return () => dispatch(setDiscountDetail({}));
    }, [pid]);


    const { reset, ...restForm } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        keepDirty: true,
        defaultValues
    });

    async function onSubmitHandler(fdata) {
       
        const postData = Object.fromEntries(
            Object.entries(fdata).filter(([_, v]) => v != null)
        );

        let formData = {}
        
        Object.keys(postData).forEach((key) => {
            if(key === "product") formData.product_id = postData[key]?.id;
            else formData[key] = postData[key]
        });

        await updateDiscount(id, formData).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar("Discount update success", {
                    variant: 'success',
                });
                //dispatch(setDiscountDetail(res.data));
                history.push({
                    pathname: pages.DISCOUNT_LIST + "/product/" + pid + "/rates",
                });
            } else {
                enqueueSnackbar("Discount update failed", {
                    variant: 'error',
                });
            }
        });
    };
    return (
        <DiscountForm
            {...restForm}
            onSubmitHandler={onSubmitHandler}
            isNewItem={false}
        />
    );
}
