import { ActionTypes } from "../contants/action-types"

const setVendorSliderList = (vendorSliderList) => {
    return {
        type: ActionTypes.VENDOR_SLIDER_LIST,
        payload: vendorSliderList
    }
};


const setVendorSliderDetail = (vendorSlider) => {
    return {
        type: ActionTypes.VENDOR_SLIDER_DETAIL,
        payload: vendorSlider
    }
};
export { setVendorSliderList, setVendorSliderDetail }
