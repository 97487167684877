import { ActionTypes } from "../contants/action-types"

export const searchReducer = (state = "", { type, payload }) => {
    switch (type) {
        case ActionTypes.SEARCH:
            return payload;
        default:
            return state;
    }
}
