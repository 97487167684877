// @mui material components
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// Soft UI Dashboard React components
import Box from "@mui/material/Box";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// Soft UI Dashboard React example components
import Table from "layouts/Table";
import { pages } from "links";
import { Link } from 'react-router-dom';
// Custom styles for the Tables
import styles from "./styles";

// Data
import { columns, modelList, modelListInit, modelListEmpty, modelPages } from "../model/list";
import { getDiscountDelete, getDiscountList } from "../service";;
import { useDispatch, useSelector } from "react-redux";
import { setDiscountList } from "redux/action/discountAction";
import { useSnackbar } from "notistack";
import { deleteAlert } from "_services";
import { getProductDetail } from "view/main/products/service";
import { discountTitle } from "links";

// main component
export default function DiscountList() {
    const [product,setProduct] = useState()
    const { enqueueSnackbar } = useSnackbar()
    const classes = styles();
    const history = useHistory();
    const { pid } = useParams();
    const { discounts, pg } = useSelector(state => state.discountList);
    const dispatch = useDispatch();
    const handleView = (id, name) => {
        history.push({
            pathname: pages.DISCOUNT_VIEW,
            search: "?" + (name.replace(" ", "-").toLowerCase()),
            state: {
                id,
                name
            }
        });
    }
    const handleClick = (_, current) => {
        if (pg.current != current) {
            loadData(current);
        }
    }

    const handleDelete = async (id) => {
        const deleteHandler = () => getDiscountDelete(id);
        deleteAlert({ title: "Are you sure?"}, deleteHandler)
        .then((res) => {
            if (res === null) {
                return;
            }
            if (res.status === 200) {
                enqueueSnackbar("Discount delete success", {
                    variant: 'success',
                });
                loadData(1);
            } else {
                enqueueSnackbar("Discount delete failed", {
                    variant: 'error',
                });
            }
        });

    }

    async function loadData(page) {
        await getDiscountList({ page }).then((res) => {
            if (res.flag) {
                
                const filteredData = res.data.data?.discounts.filter((d) => d?.product_id == pid);
                if (Object.keys(res.data).length) {
                    dispatch(setDiscountList({
                        discounts: filteredData,
                        pg: {
                            size: 0,
                            pages: 0,
                            current: 0,
                            total: 0,
                        }
                    }));
                } else {
                    dispatch(setDiscountList({
                        discounts: [],
                        pg: {
                            size: 0,
                            pages: 0,
                            current: 0,
                            total: 0,
                        }
                    }));
                }
            }

        });
    }

    async function loadProductData(p_id) {
        await getProductDetail(p_id).then((res) => {
            if (res.flag) {
                setProduct(res.data?.data?.product);
            }
        });
    }

    useEffect(() => {
        loadData(1);
        if(pid) loadProductData(pid);

        return () => {
            dispatch(setDiscountList({ discounts: [], pg: {} }));
        }
    }, []);

    const TableRender = () => {
        if (discounts === null) {
            return (
                <div>
                    <Table columns={columns} rows={modelListInit()} />
                </div>
            );
        } else if (discounts.length == 0) {
            return (
                <div>
                    <Table columns={columns} rows={modelListEmpty()} />
                </div>
            );
        } else {
            return (
                <div>
                    <Table columns={columns} pagination={pg} rows={modelList(discounts, handleView, handleDelete, history)} />
                    <Box mt={1} mb={2} style={{ float: "right" }}>
                        {modelPages(pg, handleClick)}
                    </Box>
                </div>
            );
        }
    }

    return (
        <Box py={3}>
            <Box mb={3}>
                <Card>
                    <Box display="flex" justifyContent="space-between" aligndiscounts="center" p={3}>
                        <SuiTypography variant="h6">
                           { !!pid && 
                                <IconButton 
                                 aria-label="back" 
                                 size="medium"
                                 sx={{
                                    'verticalAlign': '-7px',
                                    'paddingLeft':"0"
                                 }} 
                                 onClick={ () => history.push({ pathname: pages.PRODUCT_LIST }) 
                                }>
                                    <ArrowBackIcon size={28}/>
                                </IconButton>
                            }
                            {discountTitle.DISCOUNT_LIST} for { product?.name }
                        </SuiTypography>
                        <Link to={pages.DISCOUNT_LIST + "/product/" + pid + "/add"}>
                            <SuiButton buttonColor="secondary">Add Discount</SuiButton>
                        </Link>
                    </Box>
                    <SuiBox customClass={classes.tables_table}>
                        <TableRender />
                    </SuiBox>
                </Card>
            </Box>
        </Box>
    );
}
