import { 
    getApiRequest as getApi,
    getJsonForm,
    getApiDeleteRequest,
    getApiPatchRequest
} from '_services';

const getApiRequest = async (requestData) => {
    const result = await getApi({
        ...requestData
    }).then(function (response) {
        return { flag: true, headers: response.headers, status: response.status, message: "Success", data: response.data };
    }).then(function (finalJson) {
        return finalJson;
    }).catch((error) => {
        return { flag: false, status: error.status, message: "Error", data: error.data };
    });
    return result;
}


async function getDiscountList(param) {
    return await getApiRequest({
        url: "/product-discount",
        data: {
            ...param
        }
    }).then((result) => {
        return result;
    });
}

async function getDiscountDetail(id) {
    return await getApiRequest({
        url: "/product-discount/single/" + id
    }).then((result) => {
        return result;
       
    });
}

async function getDiscountDelete(id) {
    return await getApiDeleteRequest("/product-discount/delete/" + id).then((result) => {
        return result;
    });
}


async function addDiscount(idata) {
    return await getApiRequest({
        url: "/product-discount/store",
        method: "post",
        data: idata
    }).then((result) => {
        return result;
    });
}

async function updateDiscount(id, idata) {
    return await getApiPatchRequest("/product-discount/update/" + id, idata).then((result) => {
        return result;
    });
}

export {
    getDiscountList,
    getDiscountDetail,
    addDiscount,
    updateDiscount,
    getDiscountDelete
};