import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { pages } from "links";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import ProgressBar from "components/ProgressBar";

import { getLineChart, getBarChart } from "../model/list";
import { getDashboard } from "../service";
import { useDispatch, useSelector } from "react-redux";
import { setDashboard } from "redux/action/dboardAction";
import MiniStatisticsCard from "layouts/Cards/StatisticsCards/MiniStatisticsCard";
import GradientLineChart from "layouts/Charts/LineCharts/GradientLineChart";
import { Link } from "react-router-dom"
function Dashboard() {
  const dboardData = useSelector(state => state.dboard);
  const dispatch = useDispatch();
  async function loadData() {
    await getDashboard().then((res) => {
      console.log(res);
      if (res.flag) {
        console.log(res.data);
        if (Object.keys(res.data).length) {
          dispatch(setDashboard({
            minicard: {
              totalUser: res.data.data.totalUser,
              totalVendor: res.data.data.totalVendor,
              totalProduct: res.data.data.totalProduct,
              totalCategory: res.data.data.totalCategory
            },
            highestOrderValueReport: getLineChart(res.data.data.highestOrderValueReport),
            orderReportForAllStatus: getLineChart(res.data.data.orderReportForAllStatus),
            trendingSalesReport: getLineChart(res.data.data.trendingSalesReport),
            // bar: getBarChart(res.data.data.bar[0]),
          }));
        } else {
          dispatch(setDashboard({}));
        }
      }
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  if (dboardData === null || Object.keys(dboardData).length == 0) {
    return (<ProgressBar />);
  }

  const minicard = dboardData.minicard;
  console.log(dboardData);
  return (
    <SuiBox py={3}>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to={pages.CUSTOMER_LIST}>
              <MiniStatisticsCard
                title={{ text: "Customer" }}
                count={minicard.totalUser}
                // percentage={{ color: dboardData.sales_pay < 0 ? "error" : "success", text: dboardData.sales_pay }}
                icon={{ color: "info", component: "people" }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to={pages.VENDOR_LIST}>
              <MiniStatisticsCard
                title={{ text: "Vendor" }}
                count={minicard.totalVendor}
                // percentage={{ color: dboardData.purchase_pay < 0 ? "error" : "success", text: dboardData.purchase_pay }}
                icon={{ color: "info", component: "store" }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to={pages.PRODUCT_LIST}>
              <MiniStatisticsCard
                title={{ text: "Product" }}
                count={minicard.totalProduct}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: "shopping_bag" }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to={pages.CATEGORY_LIST}>
              <MiniStatisticsCard
                title={{ text: "Category" }}
                count={minicard.totalCategory}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "info",
                  component: "category"
                }}
              />
            </Link>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <GradientLineChart
              title="Order Overview"
              height="15.25rem"
              chart={dboardData.orderReportForAllStatus}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <GradientLineChart
              title="Highest Order Value"
              height="15.25rem"
              chart={dboardData.highestOrderValueReport}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <GradientLineChart
              title="Trending Sales"
              height="15.25rem"
              chart={dboardData.trendingSalesReport}
            />
          </Grid>
          {/* <Grid item xs={12} lg={4}>
            <ReportsBarChart
              title="active vendor"
              chart={dboardData.bar}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <PieChart
              title="Sales Report"
              height="20rem"
              chart={dboardData.salesPie}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <PieChart
              title="Purchase Report"
              height="20rem"
              chart={dboardData.purchasePie}
            />
          </Grid> */}
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}




export default Dashboard;
