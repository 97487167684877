import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import PersonIcon from '@mui/icons-material/Person';
import tokenService from '_services/token.service';
import { pages } from 'links';

export default function DropDown() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="User" color="white" onClick={handleClick}>
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              history.push({
                pathname: pages.PROFILE
              });
            }}>
            <PersonIcon sx={{ mr: 1 }} />
            Profile
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            color="error"
            onClick={() => {
              tokenService.logout()
              history.push({
                pathname: pages.GUEST
              });
            }}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}