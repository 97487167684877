import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { Controller, useFieldArray } from "react-hook-form";
// Soft UI Dashboard React components
import SuiTypography from "components/SuiTypography";
import { Box, MenuItem } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from "prop-types";
// import Icon from "@mui/material/Icon";
// import SelectInput from "components/SelectInput";
import productIcon from "assets/images/product.png";

const VendorSliderForm = ({ 
    control, 
    handleSubmit, 
    onSubmitHandler, 
    isNewItem, 
    errors, 
    getValues, 
    setValue 
}) => {

    const [selectedImage, setSelectedImage] = useState("");
    let cnt = 0, errorList = [];


    for (const key in errors) {
        errorList[cnt] = (
            <SuiTypography key={key} textColor="error" variant="caption" component="li">
                {errors[key].message}
            </SuiTypography>
        );
        cnt++;
    }

    const imageChange = (e, field) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };

    return (
        <Box py={3}>
            <Box mb={3}>
                <Card>
                    <Box pt={2} pb={3} px={3}>
                        <form onSubmit={ handleSubmit(onSubmitHandler) }>
                            <Grid container spacing={2} direction="row" style={{ maxWidth: 500}}>
                            <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <SuiTypography variant="subtitle2" component="div">VendorSlider Title*</SuiTypography>
                                            <Controller
                                                name="title"
                                                control={control}
                                                defaultValue=""
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <>
                                                            <SuiInput {...field} error={fieldState.invalid ? fieldState.invalid : undefined} />
                                                            <SuiTypography textColor="error" variant="caption" component="span">{fieldState.error?.message}</SuiTypography>
                                                        </>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <SuiTypography variant="subtitle2" component="div">Status</SuiTypography>
                                            <Controller
                                                name="status"
                                                control={control}
                                                defaultValue={""}
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <Box sx={{padding:"0 1rem"}}>
                                                            <RadioGroup
                                                                {...field}
                                                                defaultValue="1"
                                                                row
                                                                sx={{ gap: 1 }}
                                                            >
                                                                <FormControlLabel value="1" control={<Radio />} label="Active" />
                                                                <FormControlLabel value="0" control={<Radio />} label="Inactive" />
                                                            </RadioGroup>
                                                            {/* <SuiTypography textColor="error" variant="caption" component="span">
                                                                {fieldState?.errors?.message}
                                                            </SuiTypography> */}
                                                        </Box>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container
                                        spacing={1}
                                        direction="column"
                                        alignItems="start"
                                    >
                                        <Grid item xs={12} >
                                            <SuiTypography variant="subtitle2" component="div">
                                                VendorSlider Image*
                                            </SuiTypography>
                                            <SuiBox
                                                style={{ border: "1px solid #eee", height: "150px", display: "flex" }}
                                                borderRadius="lg" boxShadow="sm" p={1} mt={2} mb={1}
                                            >
                                                <img src={
                                                          selectedImage ? URL.createObjectURL(selectedImage) :
                                                           (getValues("img_view") ? getValues("img_view") : 
                                                           productIcon)
                                                        }
                                                    style={{ margin: "auto", width: "100%", height: "auto", maxHeight: "100%" }}
                                                />
                                            </SuiBox>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Controller
                                                name="cover_image"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <SuiButton
                                                            variant="contained"
                                                            component="label"
                                                        >
                                                            Select File
                                                            <input
                                                                type="file"
                                                                hidden
                                                                onChange={(e) => {
                                                                    imageChange(e);
                                                                    field.onChange(e.target.files);
                                                                }}
                                                                accept="image/*"
                                                            />
                                                        </SuiButton>
                                                        <br />
                                                        <SuiTypography textColor="error" variant="caption" component="span">
                                                            { fieldState.error?.message }
                                                        </SuiTypography>
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={9}>
                                    {errorList}
                                </Grid> */}
                                <Grid item xs={12}>
                                    <SuiButton 
                                      style={{ float: "right" }} 
                                      type="submit" 
                                      buttonColor={isNewItem ? "primary" : "secondary"}
                                    >
                                        {isNewItem ? "Save" : "Update"}
                                    </SuiButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Card>
            </Box>
        </Box >
    );
}

VendorSliderForm.defaultProps = {
    isNewItem: true,
};

VendorSliderForm.propTypes = {
    isNewItem: PropTypes.bool,
    control: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
};

export default VendorSliderForm;