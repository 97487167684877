/* eslint-disable react/prop-types */
import SuiTypography from "components/SuiTypography";
// Images
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import { AvatarSm } from "custom/Avatar";
import Text from "custom/Text";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { discountPage } from "links";

const modelList = (list, handleView, handleDelete, history) => {
  return list.map(
    ({ id, min_qty, rate, label, product }, index) => {
      return {
        index: <Text edge={true} ml={1} text={index + 1} />,
        label: <Text text={label} />,
        min_qty: <Text text={min_qty} />,
        rate: <Text text={rate} />,
        product: <Text text={product?.name} />,
        action: (
          <>
            <IconButton onClick={() => history.push(`${discountPage.DISCOUNT_LIST}/${id}/product/${product?.id}/update`)} color="secondary" size="small" title="Edit">
              <BorderColorIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(id)} color="secondary" size="small" title="Delete">
              <DeleteForeverIcon />
            </IconButton>
          </>
        ),
      };
    }
  );
};

const modelListEmpty = () => {
  return [
    {
      image: [
        { colSpan: "7", style: { textAlign: "center" } },
        <SuiTypography
          component="span"
          textColor="secondary"
          fontWeight="medium"
          p={20}
        >
          No data found
        </SuiTypography>,
      ],
    },
  ];
};

const modelPages = (pagination, handleNav) => {
  return (
    <Pagination
      variant="outlined"
      count={pagination.pages}
      page={pagination.current}
      color="primary"
      siblingCount={2}
      boundaryCount={2}
      onChange={handleNav}
    />
  );
};

const modelListInit = () => {
  return [
    {
      image: (
        <Skeleton
          animation="wave"
          style={{ margin: "5px 10px" }}
          variant="circular"
          width={40}
          height={40}
        />
      ),
      name: [
        { colSpan: "4" },
        <Skeleton animation="wave" variant="text" width="80%" height={30} />,
      ],

    }
  ];
};

const columns = [
  { name: "sno", type: "serial_no", label: "#", align: "center" },
  { name: "label", align: "left" },
  { name: "min_qty", align: "left" },
  { name: "rate", align: "left" },
  { name: "product", align: "left" },
  { name: "action", align: "center" },
];

export { columns, modelList, modelListInit, modelListEmpty, modelPages };
