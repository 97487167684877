import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { menuReducer } from "./menuReducer";
import { itemDetailReducer, itemListReducer } from "./itemReducer";
import { vendDetailReducer, vendListReducer } from "./vendReducer";
import { contactDetailReducer, contactListReducer } from "./contactReducer";
import { customerListReducer, customerDetailReducer } from "./customerReducer";
import { navPathReducer } from "./navPathReducer";
import { alertReducer } from "./alertReducer";
import { dboardReducer } from "./dboardReducer";
import { categoryDetailReducer, categoryListReducer } from "./categoryReducer";
import { sliderDetailReducer, sliderListReducer } from "./sliderReducer";
import { vendorDetailReducer, vendorListReducer } from "./vendorReducer";
import { vendorSliderDetailReducer, vendorSliderListReducer } from "./vendorSliderReducer";
import { productDetailReducer, productListReducer } from "./productReducer";
import { galleryDetailReducer, galleryListReducer } from "./galleryReducer";
import { discountDetailReducer, discountListReducer } from "./discountReducer";
import { pageDetailReducer, pageListReducer } from "./pageReducer";
import { variationDetailReducer, variationListReducer } from "./variationReducer";
import { variationAttributeDetailReducer, variationAttributeListReducer } from "./variationAttributeReducer";
import { faqDetailReducer, faqListReducer } from "./faqReducer";
import { inquiryDetailReducer, inquiryListReducer } from "./inquiryReducer";
import { subscribeListReducer } from "./subscribeReducer";
import { voucherListReducer } from "./voucherReducer";
import { searchReducer } from "./searchReducer";
import { reviewDetailReducer, reviewListReducer } from "./reviewReducer";

const reducers = combineReducers({
    user: userReducer,
    menu: menuReducer,
    itemDetail: itemDetailReducer,
    itemList: itemListReducer,
    vendDetail: vendDetailReducer,
    vendList: vendListReducer,
    contactList: contactListReducer,
    contactDetail: contactDetailReducer,
    customerList: customerListReducer,
    customerDetail: customerDetailReducer,
    navPath: navPathReducer,
    alert: alertReducer,
    dboard: dboardReducer,
    categoryList: categoryListReducer,
    categoryDetails: categoryDetailReducer,
    sliderList: sliderListReducer,
    sliderDetails: sliderDetailReducer,
    voucherList: voucherListReducer,
    vendorList: vendorListReducer,
    vendorDetails: vendorDetailReducer,
    vendorSliderList: vendorSliderListReducer,
    pageList: pageListReducer,
    pageDetails: pageDetailReducer,
    variationList: variationListReducer,
    variationDetails: variationDetailReducer,
    variationAttributeList: variationAttributeListReducer,
    variationAttributeDetails: variationAttributeDetailReducer,
    productList: productListReducer,
    productDetails: productDetailReducer,
    galleryList: galleryListReducer,
    galleryDetails: galleryDetailReducer,
    discountList: discountListReducer,
    discountDetails: discountDetailReducer,
    faqList: faqListReducer,
    faqDetails: faqDetailReducer,
    inquiryList: inquiryListReducer,
    inquiryDetails: inquiryDetailReducer,
    subscribeList: subscribeListReducer,
    search: searchReducer,
    reviewList: reviewListReducer,
    reviewDetail: reviewDetailReducer

});

export default reducers;