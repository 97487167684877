
const stat = {
  "0": {
    "batch": "Pending",
    "color": "info"
  },
  "1": {
    "batch": "Ordered",
    "color": "primary"
  },
  "2": {
    "batch": "Received",
    "color": "success"
  },
  "3": {
    "batch": "Return",
    "color": "error"
  }
};
const colorList = ["info", "warning", "success", "error"];
let monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const getLineChart = (dataList) => {
console.log(dataList);
  const datasets = dataList.datasets?.map((val, key) => {
    return {
      ...val,
      color: colorList[key],
    }
  });

  return {
    labels: dataList.labels,
    datasets
  };
};

const getBarChart = (dataList) => {

  let datasets = {};

  let montdata = [];


  monthList.forEach((month, key) => {
    if (dataList.data.hasOwnProperty(month)) {
      montdata[key] = dataList.data[month];
    } else {
      montdata[key] = 0;
    }
  });

  datasets = {
    label: dataList.name,
    color: colorList[0],
    data: montdata
  }

  return {
    labels: monthList,
    datasets: datasets,
  };
}

export {
  getLineChart,
  getBarChart,
}