import { Box } from "@mui/material";
import SuiTypography from "components/SuiTypography";

export default ({ text, edge, warpLength }) => {
  return (
    <Box
      display="flex"
      pl={edge ? 2.5 : 0.5}
      pr={edge ? 2.5 : 0.5}
      pt={1.5}
      pb={1.25}
    >
      <SuiTypography
        variant="caption"
        fontWeight="medium"
        warpLength={warpLength}
      >
        {text}
      </SuiTypography>
    </Box>
  );
};
