class TokenService {
    static store;
    static setStore(store) {
        this.store = store;
    }
    static interval;
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("userModel"));
        return user?.user.refresh_token;
    }
    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("userModel"));
        return user?.access_token;
    }
    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem("userModel"));
        user.access_token = token;
        localStorage.setItem("userModel", JSON.stringify(user));
    }
    getUser() {
        const userModel = localStorage.getItem("userModel");
        if (userModel === null) {
            return null
        }
        const user = JSON.parse(userModel)
        return user.user;
    }
    setUser(user) {
        user["isLogin"] = true;
        localStorage.setItem("userModel", JSON.stringify(user));
    }
    updateUser(user) {
        const userModel = localStorage.getItem("userModel");
        let userOld = JSON.parse(userModel);
        user.user = {
            ...userOld,
            ...user
        }
        localStorage.setItem("userModel", JSON.stringify(user));
    }
    removeUser() {
        localStorage.removeItem("userModel")
        // this.store.dispatch(setLogout());
    }
    logout() {
        localStorage.removeItem("userModel")
        // this.store.dispatch(setLogout());
    }
}
export default new TokenService();
