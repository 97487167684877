import { ActionTypes } from "../contants/action-types"
const initList = {
    sliders: null,
    pg: {
        size: null,
        pages: null,
        current: null,
        total: null
    }
}

// const initList = [];


export const sliderListReducer = (state = initList, { type, payload }) => {
    switch (type) {
        case ActionTypes.SLIDER_LIST:
            return { ...state, ...payload };
        default:
            return state;
    }
}

export const sliderDetailReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case ActionTypes.SLIDER_DETAIL:
            return { ...payload };
        default:
            return state;
    }
}