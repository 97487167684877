import { Route } from 'react-router-dom';
import { pages } from "links/pages";
import DiscountList from './view/list';
import DiscountAdd from './view/create';
import DiscountUpdate from './view/update';

export default function DiscountController() {
    return (
        <>
            <Route exact path={pages.DISCOUNT_LIST + "/product/:pid" + "/rates"} component={DiscountList} />
            <Route exact path={pages.DISCOUNT_LIST + "/:id/product/:pid/update"} component={DiscountUpdate} />
            <Route exact path={pages.DISCOUNT_LIST + "/product/:pid/add"} component={DiscountAdd} />
        </>
    );
}