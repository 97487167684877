const ActionTypes = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    MENU: "MENU",
    SEARCH: "SEARCH",
    NAV_PATH: "NAV_PATH",
    ITEM_LIST: "ITEM_LIST",
    ITEM_DETAIL: "ITEM_DETAIL",
    VEND_LIST: "VEND_LIST",
    VEND_DETAIL: "VEND_DETAIL",
    CONTACT_LIST: "CONTACT_LIST",
    CONTACT_DETAIL: "CONTACT_DETAIL",
    CUSTOMER_LIST: "CUSTOMER_LIST",
    CUSTOMER_DETAIL: "CUSTOMER_DETAIL",
    DASHBOARD: "DASHBOARD",
    ALERT_MSG: "ALERT_MSG",
    CATEGORY_LIST: "CATEGORY_LIST",
    CATEGORY_DETAIL: "CATEGORY_DETAIL",
    SLIDER_LIST: "SLIDER_LIST",
    SLIDER_DETAIL: "SLIDER_DETAIL",
    VENDOR_LIST: "VENDOR_LIST",
    VENDOR_DETAIL: "VENDOR_DETAIL",
    VENDOR_SLIDER_LIST: "VENDOR_SLIDER_LIST",
    VENDOR_SLIDER_DETAIL: "VENDOR_SLIDER_DETAIL",
    PAGE_LIST: "PAGE_LIST",
    PAGE_DETAIL: "PAGE_DETAIL",
    VOUCHER_LIST: "VOUCHER_LIST",
    VARIATION_LIST: "VARIATION_LIST",
    VARIATION_DETAIL: "VARIATION_DETAIL",
    VARIATION_ATTRIBUTE_LIST: "VARIATION_ATTRIBUTE_LIST",
    VARIATION_ATTRIBUTE_DETAIL: "VARIATION_ATTRIBUTE_DETAIL",
    PRODUCT_LIST: "PRODUCT_LIST",
    PRODUCT_DETAIL: "PRODUCT_DETAIL",
    GALLERY_LIST: "GALLERY_LIST",
    GALLERY_DETAIL: "GALLERY_DETAIL",
    DISCOUNT_LIST: "DISCOUNT_LIST",
    DISCOUNT_DETAIL: "DISCOUNT_DETAIL",
    FAQ_LIST: "FAQ_LIST",
    FAQ_DETAIL: "FAQ_DETAIL",
    INQUIRY_LIST: "INQUIRY_LIST",
    INQUIRY_DETAIL: "INQUIRY_DETAIL",
    SUBSCRIBE_LIST: "SUBSCRIBE_LIST",
    REVIEW_LIST:"REVIEW_LIST",
    REVIEW_DETAIL:"REVIEW_DETAIL",
};

const MenuAction = {
    MINI_SIDENAV: "MINI_SIDENAV",
    TRANSPARENT_NAVBAR: "TRANSPARENT_NAVBAR",
    FIXED_NAVBAR: "FIXED_NAVBAR",
    LAYOUT: "LAYOUT",
};

export { ActionTypes, MenuAction };