
// Soft UI Dashboard React example components
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory,useParams } from "react-router-dom";
import { discountPage } from "links/pages";
import { getProductDetail } from "view/main/products/service";
import schema,{defaultValues} from "../schema";
import DiscountForm from "./_form";
// Custom styles for the Tables

// Service
import { addDiscount } from "../service";

export default function DiscountAdd() {
    
    const { reset, formState: { errors }, ...restForm } = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const { pid } = useParams();

    async function onSubmitHandler(fdata) {
        const postData = Object.fromEntries(
            Object.entries(fdata).filter(([_, v]) => v != null)
        );

        let formData = {}
        
        Object.keys(postData).forEach((key) => {
            if(key === "product") formData.product_id = postData[key]?.id;
            else formData[key] = postData[key]
        });
       
        await addDiscount(formData).then(({ flag, data }) => {
            if (flag) {
                enqueueSnackbar("Discount added successfully!", {
                    variant: 'success',
                });
                history.push({
                    pathname: discountPage.DISCOUNT_LIST + "/product/" + pid + "/rates",
                    // search: "?" + (data.name.replace(" ", "-").toLowerCase()),
                    // state: {
                    //     id: data.id,
                    //     name: data.name
                    // }
                });
            }
        });
    };

    async function loadProductData(p_id) {
        await getProductDetail(p_id).then((res) => {
            if (res.flag) {
                reset({
                    product: res.data?.data?.product
                });
            }
        });
    }

    useEffect(() => {
        loadProductData(pid)
    },[pid])

    return (
        <DiscountForm
            {...restForm}
            onSubmitHandler={onSubmitHandler}
            errors={errors}
        />
    );
}
