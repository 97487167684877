
// Soft UI Dashboard React example components
import { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { pages } from "links/pages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import VendorSliderForm from "./_form";
// Custom styles for the Tables

// Service
import { updateVendorSlider, getVendorSliderDetail } from "../service"

const schema = yup.object({
    image: yup.mixed().test("fileSize", "Max file size is 3MB", (value, ob1) => {
        return !value || value[0].size < 3150000;
    }).test("type", "Only jpg,jpeg, webp and png are supported", (value) => {
        return !value || (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/webp" || value[0].type === "image/png");
    }),
    title: yup.string().required().max(100).label('VendorSlider Title')
});

export default function VendorSliderUpdate() {

    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const history = useHistory();

    async function loadData() {
        await getVendorSliderDetail(id)
            .then((res) => {
                if (res.flag) {
                    const itData = res.data?.data.slider;
                    reset({
                        title: itData?.title,
                        image: null,
                        status: itData?.status,
                        img_view: itData?.cover_image_link
                    }, {
                        keepDirty: true
                    });
                }  
            });
    }

    useEffect(() => {
        // if (Object.keys(itemData).length === 0 && id !== undefined) {
        //     loadData();
        // }
        loadData();
        // return () => dispatch(setVendorSliderDetail({}));
    }, []);


    const { reset, ...restForm } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        keepDirty: true,
        defaultValues: {
            title: "",
            cover_image: null,
            status: 1,
            img_view: ""
           }
    });

    async function onSubmitHandler(fdata) {
        const postData = Object.fromEntries(
            Object.entries(fdata).filter(([key, v]) => v != null)
        );
        const formData = new FormData();
        Object.keys(postData).forEach((key) => {
            if(key === "cover_image") formData.append(key,postData[key][0]);
            if(key != "img_view") formData.append(key,postData[key])
        });
        await updateVendorSlider(id, formData).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar("VendorSlider update success", {
                    variant: 'success',
                });
                //dispatch(setVendorSliderDetail(res.data));
                history.push({
                    pathname: pages.SLIDER_LIST,
                });
            } else {
                enqueueSnackbar("VendorSlider update failed", {
                    variant: 'error',
                });
            }
        });
    };
    return (
        <VendorSliderForm
            {...restForm}
            onSubmitHandler={onSubmitHandler}
            isNewItem={false}
        />
    );
}
