import SuiBadge from "components/SuiBadge";

const dstat = {
  1: {
    batch: "active",
    color: "success",
  },
  0: {
    batch: "inactive",
    color: "secondary",
  },
};

const pstat = {
  0: {
    batch: "Pending",
    color: "warning",
  },
  1: {
    batch: "Partial",
    color: "primary",
  },
  2: {
    batch: "Paid",
    color: "success",
  },
};

const itemProgress = {
  order_placed: {
    batch: "Order Placed",
    color: "info",
    id: "order_placed",
    next: "under_production",
  },
  under_production: {
    batch: "Under Production",
    color: "primary",
    id: "under_production",
    next: "production_completed",
  },
  production_completed: {
    batch: "Production Completed",
    color: "info",
    id: "production_completed",
    next: "payment_completed",
  },
  payment_completed: {
    batch: "Payment Completed",
    color: "warning",
    id: "payment_completed",
    next: "shipped",
  },
  shipped: {
    batch: "Shipped",
    color: "success",
    id: "shipped",
    next: "delivered",
  },
  delivered: {
    batch: "Delivered",
    color: "success",
    id: "delivered",
    next: "order_placed",
  },

};

const orderStatus = {
  order_placed: {
    batch: "Order Placed",
    color: "info",
    id: "order_placed",
  },
  in_progress: {
    batch: "In Progress",
    color: "primary",
    id: "under_production",
  },
  completed: {
    batch: "Completed",
    color: "success",
    id: "completed",
  },
  cancelled: {
    batch: "Cancelled",
    color: "error",
    id: "cancelled",
  },
};

function ProgressStatus({ status }) {
  return (
    <SuiBadge
      variant="gradient"
      badgeContent={itemProgress[status].batch}
      color={itemProgress[status].color}
      size="extra-small"
    />
  );
}


const DataStatus = ({ status }) => {
  console.log(status)
  return (
    <SuiBadge
      variant="gradient"
      badgeContent={dstat[status].batch}
      color={dstat[status].color}
      size="extra-small"
    />
  );
};

const PaymentStatus = ({ status }) => {
  return (
    <SuiBadge
      variant="gradient"
      badgeContent={pstat[status].batch}
      color={pstat[status].color}
      size="extra-small"
    />
  );
};

const OrderStatus = ({ status }) => {
  return (
    <SuiBadge
      variant="gradient"
      badgeContent={orderStatus[status].batch}
      color={orderStatus[status].color}
      size="extra-small"
    />
  );
};

export { OrderStatus, PaymentStatus, DataStatus, ProgressStatus, itemProgress };
