
// Soft UI Dashboard React example components
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import VendorSliderForm from "./_form";
import { useHistory } from "react-router-dom";
import { sliderPage } from "links/pages";
// Custom styles for the Tables

// Service
import { addVendorSlider } from "../service";

const schema = yup.object({
    cover_image: yup.mixed().test("fileSize", "Max file size is 3MB", (value, ob1) => {
        return !value || value[0].size < 3150000;
    }).test("type", "Only jpg,jpeg, webp and png are supported", (value) => {
        return !value || (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/webp" || value[0].type === "image/png");
    }).required("Select an image."),
    title: yup.string().required("Add a title for the slider.").max(100).label('VendorSlider Title')
});

export default function VendorSliderAdd() {
    
    const { formState: { errors }, ...restForm } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: "",
            cover_image: null,
            status: 1
        }
    });
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();

    async function onSubmitHandler(fdata) {
        const postData = Object.fromEntries(
            Object.entries(fdata).filter(([_, v]) => v != null)
        );
        const formData = new FormData();
        Object.keys(postData).forEach((dt) => {
            if(dt === "cover_image") formData.append(dt,postData[dt][0]);
            else formData.append(dt,postData[dt])
        });
       
        await addVendorSlider(formData).then(({ flag, data }) => {
            if (flag) {
                enqueueSnackbar("VendorSlider added successfully!", {
                    variant: 'success',
                });
                history.push({
                    pathname: sliderPage.SLIDER_LIST,
                    // search: "?" + (data.name.replace(" ", "-").toLowerCase()),
                    // state: {
                    //     id: data.id,
                    //     name: data.name
                    // }
                });
            }
        });
    };
    return (
        <VendorSliderForm
            {...restForm}
            onSubmitHandler={onSubmitHandler}
            errors={errors}
        />
    );
}
