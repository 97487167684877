import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";

import { useHistory, useParams } from "react-router-dom";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Grid from "@mui/material/Grid";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
// Soft UI Dashboard React components
import { pages } from "links/pages";
import userIcon from 'assets/images/user.png';
// Service
import { getProfileDetail } from "../service";
import { DataStatus } from 'custom/Status';
import tokenService from "_services/token.service";
import SuiButton from "components/SuiButton";
import { Icon } from "@mui/material";


function ProfileView() {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [profileData, setProfile] = useState(tokenService.getUser());

    async function loadData() {
        await getProfileDetail(profileData.id)
            .then((res) => {
                if (res.flag) {
                    setProfile(res.data.data.admin);
                }
            })
    }

    const updateProfile = () => {
        history.push({
            pathname: pages.PROFILE_UPDATE,
            search: "?" + (profileData.name.replace(" ", "-").toLowerCase()),
            state: {
                name: profileData.name
            }
        });
    }


    if (Object.keys(profileData).length === 0) {
        return (<>
            <SuiBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                backgroundColor="white"
                borderRadius="lg"
                p={3}
                mb={1}
                mt={2}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Skeleton animation="wave" variant="text" width="30%" height={30} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Skeleton style={{ margin: "10px" }} animation="wave" variant="rectangular" width="100%" height={"100%"} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Skeleton animation="wave" variant="text" width="30%" height={30} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton animation="wave" variant="text" width="20%" height={30} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton animation="wave" variant="text" width="40%" height={30} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton animation="wave" variant="text" width="20%" height={30} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SuiBox>
        </>)
    }

    return (
        <>
            <SuiBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                backgroundColor="white"
                borderRadius="lg"
                p={3}
                mb={1}
                mt={2}
            >
                <Box width="100%" display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        mb={2}
                    >
                        <SuiTypography fontWeight="bold" textColor="primary" textTransform="uppercase">
                            {profileData.name}
                        </SuiTypography>
                        <Box display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                            {/* <Box mr={1}>
                                <SuiButton buttonColor="error" onClick={deleteCustomer}>
                                    <Icon className="material-icons-round">delete</Icon>&nbsp;&nbsp;delete
                                </SuiButton>
                            </Box> */}
                            <SuiButton buttonColor="dark" onClick={updateProfile}>
                                <Icon className="material-icons-round">edit</Icon>&nbsp;&nbsp;Update Profile
                            </SuiButton>
                        </Box>
                    </Box>
                    <Grid container>
                        <Grid item xs={3}>
                            <SuiBox
                                style={{ border: "1px solid #eee", height: "100%", display: "flex" }}
                                borderRadius="lg"
                                boxShadow="sm"
                                p={1}
                                m={4}
                                mt={0}
                                mb={1}
                            >
                                <img
                                    src={profileData.photo_link ? profileData.photo_link : userIcon}
                                    style={{ margin: "auto", width: "100%", height: "auto" }}
                                />
                            </SuiBox>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <SuiTypography fontWeight="regular">
                                        Email
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={9}>
                                    <SuiTypography fontWeight="medium">
                                        :&nbsp;&nbsp;{profileData.email}
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    <SuiTypography fontWeight="regular">
                                        Phone
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={9}>
                                    <SuiTypography fontWeight="medium">
                                        :&nbsp;&nbsp;{profileData.phone}
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    <SuiTypography fontWeight="regular">
                                        Address
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={9}>
                                    <SuiTypography fontWeight="medium">
                                        :&nbsp;&nbsp;{profileData.address}
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    <SuiTypography fontWeight="regular">
                                        Gender
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={9}>
                                    <SuiTypography fontWeight="medium">
                                        :&nbsp;&nbsp;{profileData.gender}
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    <SuiTypography fontWeight="regular">
                                        Status
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={9}>
                                    <SuiTypography fontWeight="medium">
                                        :<DataStatus status={profileData.status} />
                                    </SuiTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </SuiBox>
        </>
    );
}

export default ProfileView;
