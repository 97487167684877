const itemPage = {
  ITEM_LIST: "/items",
  ITEM_ADD: "/items/add",
  ITEM_VIEW: "/items/view",
  ITEM_UPDATE: "/items/view/update",
};

const vendPage = {
  VEND_LIST: "/vend",
  VEND_ADD: "/vend/add",
  VEND_VIEW: "/vend/view",
  VEND_UPDATE: "/vend/view/update",
};

const contactPage = {
  CONTACT_LIST: "/contact",
  CONTACT_ADD: "/contact/add",
  CONTACT_VIEW: "/contact/view",
  CONTACT_UPDATE: "/contact/view/update",
};

const customerPage = {
  CUSTOMER_LIST: "/customer",
  CUSTOMER_VIEW: "/customer/view",
};

const purchasePage = {
  PURCHASE_LIST: "/purchase",
  PURCHASE_ADD: "/purchase/add",
  PURCHASE_VIEW: "/purchase/view",
  PURCHASE_RETURN: "/purchase/view/return",
};


const reviewPage ={
  REVIEW_LIST:"/review",
  REVIEW_ADD: "/review/add",
  REVIEW_VIEW: "/review/view", 
  REVIEW_UPDATE: "/review/view/update",
  REVIEW_DELETE: "/review/delete",

}

// const salesPage = {
//   SALES_LIST: "/sales",
//   SALES_ADD: "/sales/add",
//   SALES_VIEW: "/sales/view",
//   SALES_RETURN: "/sales/view/return",
// };

const categoryPage = {
  CATEGORY_LIST: "/categories",
  CATEGORY_ADD: "/categories/add",
  CATEGORY_UPDATE: "/categories/update",
};
const sliderPage = {
  SLIDER_LIST: "/sliders",
  SLIDER_ADD: "/sliders/add",
  SLIDER_UPDATE: "/sliders/update",
};

const salesPage = {
  SALES_LIST: "/sales",
  SALES_VIEW: "/sales/view",
  SALES_UPDATE: "/sales/update",
};


const vendorPage = {
  VENDOR_LIST: "/vendors",
  VENDOR_ADD: "/vendors/add",
  VENDOR_VIEW: "/vendors/view",
  VENDOR_UPDATE: "/vendors/update",
};

const vendorSliderPage = {
  VENDOR_SLIDER_LIST: "/vendor-sliders",
  VENDOR_SLIDER_ADD: "/vendor-sliders/add",
  VENDOR_SLIDER_UPDATE: "/vendor-sliders/update",
}

const productPage = {
  PRODUCT_LIST: "/products",
  PRODUCT_ADD: "/products/add",
  PRODUCT_VIEW: "/products/view",
  PRODUCT_UPDATE: "/products/update",
};

const galleryPage = {
  GALLERY_LIST: "/galleries",
  GALLERY_ADD: "/galleries/add",
  GALLERY_UPDATE: "/galleries/update",
};

const discountPage = {
  DISCOUNT_LIST: "/discounts",
  DISCOUNT_ADD: "/discounts/add",
  DISCOUNT_UPDATE: "/discounts/update",
};

const voucherPage = {
  VOUCHER_LIST: "/vouchers",
  VOUCHER_ADD: "/vouchers/add",
  VOUCHER_UPDATE: "/vouchers/update",
  VOUCHER_VIEW: "/vouchers/view",
};

const pagePage = {
  PAGE_LIST: "/pages",
  PAGE_ADD: "/pages/add",
  PAGE_VIEW: "/pages/view",
  PAGE_UPDATE: "/pages/update",
};

const settingsPage = {
  SETTINGS_VIEW: "/settings",
  SETTINGS_UPDATE: "/settings/update",
  SETTINGS_MIGRATE: "/settings/migrate",
};

const variationPage = {
  VARIATION_LIST: "/variations",
  VARIATION_ADD: "/variations/add",
  VARIATION_VIEW: "/variations/view",
  VARIATION_UPDATE: "/variations/update",
};

const variationAttributePage = {
  VARIATION_ATTRIBUTE_LIST: "/variation-attributes",
  VARIATION_ATTRIBUTE_ADD: "/variation-attributes/add",
  VARIATION_ATTRIBUTE_VIEW: "/variation-attributes/view",
  VARIATION_ATTRIBUTE_UPDATE: "/variation-attributes/update",
};

const faqPage = {
  FAQ_LIST: "/faqs",
  FAQ_ADD: "/faqs/add",
  FAQ_VIEW: "/faqs/view",
  FAQ_UPDATE: "/faqs/update",
};

const inquiryPage = {
  INQUIRY_LIST: "/inquiries",
  INQUIRY_VIEW: "/inquiries/view",
};

const subscribePage = {
  SUBSCRIBE_LIST: "/subscriber",
};

const sitePage = {
  LOGIN: "/guest",
  NEW_PASSWORD: "/guest/new-password",
  VERIFY_ACCOUNT: "/guest/verify-account",
  SIGNUP: "/guest/signup",
  FORGOT_PASSWORD: "/guest/forgot-password",
  VERIFY_TOKEN: "/guest/verify-token",
};

const pages = {
  // BASE_URL: "http://103.163.183.80:2035/admin",
  // BASE_URL: "http://localhost:4000/admin",
  // BASE_URL: "http://192.168.1.71:2035/admin",
  BASE_URL: "https://telship-api.rewasoft.net/admin",
  // BASE_URL: "http://127.0.0.1:3000/admin",
// live base url


  // local base url
  // BASE_URL: "http://localhost:5000/admin",

  PROFILE: "/user",
  GUEST: "/guest",
  PROFILE_UPDATE: "/user/update",
  PROFILE_UPDATE_PASSWORD: "/user/update-password",
  HOME: "/dashboard",
  DASHBOARD: "/dashboard",
  ...sitePage,
  ...itemPage,
  ...vendPage,
  ...contactPage,
  ...customerPage,
  ...purchasePage,
  ...categoryPage,
  ...salesPage,
  ...sliderPage,
  ...vendorPage,
  ...vendorSliderPage,
  ...pagePage,
  ...variationPage,
  ...variationAttributePage,
  ...productPage,
  ...galleryPage,
  ...faqPage,
  ...inquiryPage,
  ...discountPage,
  ...subscribePage,
  ...voucherPage,
  ...settingsPage,
  ...reviewPage
};

const guestPage = [
  pages.FORGOT_PASSWORD,
  pages.LOGIN,
  pages.SIGNUP,
  pages.NEW_PASSWORD,
  pages.VERIFY_ACCOUNT,
  pages.VERIFY_TOKEN,
];

const getFullUrl = (page) => {
  return pages.LOCAL_URL + "#" + page;
};


export {
  pages,
  sitePage,
  itemPage,
  vendPage,
  guestPage,
  contactPage,
  customerPage,
  salesPage,
  purchasePage,
  categoryPage,
  getFullUrl,
  sliderPage,
  vendorPage,
  pagePage,
  variationPage,
  variationAttributePage,
  productPage,
  discountPage,
  galleryPage,
  faqPage,
  inquiryPage,
  subscribePage,
  voucherPage,
  settingsPage,
  reviewPage,
};
