import {
    getApiRequest as getApi,
    getJsonForm,
    getApiDeleteRequest,
    getApiPatchRequest
} from '_services';

const getApiRequest = async (requestData) => {
    const result = await getApi({
        ...requestData
    }).then(function (response) {
        return { flag: true, headers: response.headers, status: response.status, message: "Success", data: response.data };
    }).then(function (finalJson) {
        return finalJson;
    }).catch((error) => {
        return { flag: false, status: error.status, message: "Error", data: error.data };
    });
    return result;
}

async function getProductList(param) {
    return await getApiRequest({
        url: "/product",
        data: {
            ...param
        }
    }).then((result) => {
        return result;
    });


}

async function getCategoryList() {
    return await getApiRequest({
        url: "/category/child-categories"
    }).then((result) => {
        return result;
    });


}

async function getProductDetail(id) {
    return await getApiRequest({
        url: "/product/single/" + id
    }).then((result) => {
        return result;
    });
}

async function getProductDelete(id) {
    return await getApiDeleteRequest("/product/delete/" + id).then((result) => {
        return result;
    });
}

async function addProduct(idata) {
    return await getApiRequest({
        url: "/product/store",
        method: "post",
        data: idata,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((result) => {
        return result;
    });
}

async function updateProduct(id, idata) {
    return await getApiPatchRequest("/product/update/" + id, idata).then((result) => {
        return result;
    });
}



export {
    getProductList,
    getProductDetail,
    addProduct,
    updateProduct,
    getProductDelete,
    getCategoryList
};