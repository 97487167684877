import { ActionTypes } from "../contants/action-types"

const setDiscountList = (discountList) => {
    return {
        type: ActionTypes.DISCOUNT_LIST,
        payload: discountList
    }
};


const setDiscountDetail = (discount) => {
    return {
        type: ActionTypes.DISCOUNT_DETAIL,
        payload: discount
    }
};
export { setDiscountList, setDiscountDetail }
