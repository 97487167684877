import { Route } from 'react-router-dom';
import { pages } from "links";
import ProfileView from './view/view';
import ProfileUpdate from './view/update';

function ProfileController() {
    return (
        <>
            <Route exact path={pages.PROFILE} component={ProfileView} />
            <Route exact path={pages.PROFILE_UPDATE} component={ProfileUpdate} />
        </>
    );
}

export default ProfileController;