const itemTitle = {
    ITEM_LIST: "Items",
    ITEM_ADD: "Add",
    ITEM_VIEW: "View",
    ITEM_UPDATE: "Update",
}

const vendTitle = {
    VEND_LIST: "Vendors",
    VEND_ADD: "Add",
    VEND_VIEW: "View",
    VEND_UPDATE: "Update",
}

const contactTitle = {
    CONTACT_LIST: "Contacts",
    CONTACT_ADD: "Add",
    CONTACT_VIEW: "View",
    CONTACT_UPDATE: "Update",
}
const categoryTitle = {
    CATEGORY_LIST: "Category",
    CATEGORY_ADD: "Add",
    CATEGORY_UPDATE: "Update",
}

const sliderTitle = {
    SLIDER_LIST: "Slider",
    SLIDER_ADD: "Add",
    SLIDER_UPDATE: "Update",
}

const vendorTitle = {
    VENDOR_LIST: "Vendor",
    VENDOR_ADD: "Add",
    VENDOR_UPDATE: "Update",
}

const vendorSliderTitle = {
    VENDOR_SLIDER_LIST: "Slider",
    VENDOR_SLIDER_ADD: "Add",
    VENDOR_SLIDER_UPDATE: "Update",
}

const productTitle = {
    PRODUCT_LIST: "Products",
    PRODUCT_ADD: "Add",
    PRODUCT_UPDATE: "Update",
}

const galleryTitle = {
    GALLERY_LIST: "Gallery",
    GALLERY_ADD: "Add",
    GALLERY_UPDATE: "Update",
}

const discountTitle = {
    DISCOUNT_LIST: "Discount Rates",
    DISCOUNT_ADD: "Add Discount",
    DISCOUNT_UPDATE: "Update Discount",
}

const pageTitle = {
    PAGE_LIST: "Page",
    PAGE_ADD: "Add",
    PAGE_UPDATE: "Update",
}

const variationTitle = {
    VARIATION_LIST: "Variations",
    VARIATION_ADD: "Add Variation",
    VARIATION_UPDATE: "Update Variation",
}

const variationAttributeTitle = {
    VARIATION_ATTRIBUTE_LIST: "Variation Attributes",
    VARIATION_ATTRIBUTE_ADD: "Add Variation Attribute",
    VARIATION_ATTRIBUTE_UPDATE: "Update Variation Attribute",
}

const faqTitle = {
    FAQ_LIST: "FAQs",
    FAQ_ADD: "Add",
    FAQ_UPDATE: "Update",
}

const inquiryTitle = {
    INQUIRY_LIST: "Customer Inquiries"
}

const subscribeTitle = {
    SUBSCRIBE_LIST: "Subscribers"
}

const customerTitle = {
    CUSTOMER_LIST: "Customer",
    CUSTOMER_ADD: "Add",
    CUSTOMER_VIEW: "View",
    CUSTOMER_UPDATE: "Update",
}

const purchaseTitle = {
    PURCHASE_LIST: "Purchase",
    PURCHASE_ADD: "Add",
    PURCHASE_VIEW: "View",
    PURCHASE_UPDATE: "Update",
}

const salesTitle = {
    SALES_LIST: "Sales",
    SALES_ADD: "Add",
    SALES_VIEW: "View"
}

const reviewTitle = {
    REVIEW_LIST: "Reviews",
    REVIEW_ADD: "Add",
    REVIEW_VIEW: "View"
}

const pagesTitle = {
    LOGIN: "Login",
    DASHBOARD: "Dashboard",
    PROFILE: "Profile",
    PROFILE_UPDATE: "Update Profile",
    PROFILE_UPDATE_PASSWORD: "Update Password",
    FORGOT_PASSWORD: "Forgot Password",
    NEW_PASSWORD: "New Password",
    VERIFY_ACCOUNT: "Verify Account",
    VERIFY_TOKEN: "Verify Token",
    SIGNUP: "Signup",
    SITE_TITLE: "TELASHIP ADMIN",
    ...vendTitle,
    ...itemTitle,
    ...contactTitle,
    ...purchaseTitle,
    ...categoryTitle,
    ...salesTitle,
    ...sliderTitle,
    ...vendorTitle,
    ...vendorSliderTitle,
    ...pageTitle,
    ...variationTitle,
    ...variationAttributeTitle,
    ...productTitle,
    ...galleryTitle,
    ...discountTitle,
    ...faqTitle,
    ...inquiryTitle,
    ...subscribeTitle,
    ...reviewTitle
};

export {
    pagesTitle,
    vendTitle,
    itemTitle,
    contactTitle,
    customerTitle,
    purchaseTitle,
    salesTitle,
    categoryTitle,
    sliderTitle,
    reviewTitle,
    vendorTitle,
    pageTitle,
    variationTitle,
    variationAttributeTitle,
    productTitle,
    galleryTitle,
    faqTitle,
    inquiryTitle,
    discountTitle,
    subscribeTitle
};