import { Box } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";
import userIcon from "assets/images/user.png";
const AvatarSm = ({ image, alt, defaultIcon }) => {
  return (
    <Box display="flex" alignItems="center" px={2} py={0.5}>
      <SuiAvatar
        src={image ? image : defaultIcon ? defaultIcon : userIcon}
        alt={alt}
        size="sm"
        variant="rounded"
      />
    </Box>
  );
};

export { AvatarSm };
