import * as yup from "yup";

const schema = yup.object({
    label: yup.string().required("Add a label for the discounted rate.").max(100).label('Discount label'),
    rate: yup.number().required().typeError("Invalid discounted rate."),
    min_qty: yup.number().required().typeError("Invalid minimum quantity."),
    product: yup.object().required().typeError("Select a product from the list."),
});

export const defaultValues = {
    label: "",
    rate: "",
    min_qty: "",
    product: null,
}

export default schema;
