// @mui material components
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { Controller, useFieldArray } from "react-hook-form";
// Soft UI Dashboard React components
import SuiTypography from "components/SuiTypography";
import { Box, MenuItem } from "@mui/material";
// import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SelectInput from "components/SelectInput";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { getProductList } from "view/main/products/service";


import PropTypes from "prop-types";

const DiscountForm = ({ 
    control, 
    handleSubmit, 
    onSubmitHandler, 
    isNewItem,
    errors, 
    getValues, 
    setValue,
}) => {

    let cnt = 0, errorList = [];


    for (const key in errors) {
        errorList[cnt] = (
            <SuiTypography key={key} textColor="error" variant="caption" component="li">
                {errors[key].message}
            </SuiTypography>
        );
        cnt++;
    }

    const handleOptions = async (value) => {
        return await getProductList(value)
            .then((res) => {
               
                if (res.flag) {
                    return res.data?.data;
                }
                return [];
            });
    }

    return (
        <Box py={3}>
            <Box mb={3}>
                <Card>
                    <Box pt={2} pb={3} px={3}>
                        <form onSubmit={ handleSubmit(onSubmitHandler) }>
                            <Grid container spacing={2} direction="row" style={{ maxWidth: 500}}>

                                    <Grid item xs={12}>
                                            <SuiTypography variant="subtitle2" component="div">For Product*</SuiTypography>
                                            <Controller
                                                name="product"
                                                control={control}
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <>
                                                            <SelectInput
                                                                {...field}
                                                                value={ getValues("product") }
                                                                onChange={(e, v) => {
                                                                    if (v) {
                                                                        setValue("product",v);
                                                                    } else {
                                                                        setValue("product",null);
                                                                    }
                                                                }}
                                                                handleOptions={handleOptions}
                                                                error={fieldState?.error}
                                                            />
                                                            <SuiTypography textColor="error" variant="caption" component="span">
                                                                {fieldState?.error?.message}
                                                            </SuiTypography>
                                                        </>
                                                    )
                                                }}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SuiTypography variant="subtitle2" component="div">Discount Label*</SuiTypography>
                                        <Controller
                                            name="label"
                                            control={control}
                                            defaultValue=""
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <>
                                                        <SuiInput {...field} error={fieldState.invalid ? fieldState.invalid : undefined} placeholder="Eg: Buy 10-20 / Wholesale..."/>
                                                        <SuiTypography textColor="error" variant="caption" component="span">{fieldState.error?.message}</SuiTypography>
                                                    </>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SuiTypography variant="subtitle2" component="div">Min Quantity*</SuiTypography>
                                        <Controller
                                            name="min_qty"
                                            control={control}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <>
                                                        <SuiInput {...field} error={fieldState.invalid ? fieldState.invalid : undefined} type="number"/>
                                                        <SuiTypography textColor="error" variant="caption" component="span">{fieldState.error?.message}</SuiTypography>
                                                    </>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SuiTypography variant="subtitle2" component="div">Rate*</SuiTypography>
                                        <Controller
                                            name="rate"
                                            control={control}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <>
                                                        <SuiInput {...field} error={fieldState.invalid ? fieldState.invalid : undefined} type="number" step="0.01"/>
                                                        <SuiTypography textColor="error" variant="caption" component="span">{fieldState.error?.message}</SuiTypography>
                                                    </>
                                                )
                                            }}
                                        />
                                    </Grid>
                               
                                {/* <Grid item xs={9}>
                                    {errorList}
                                </Grid> */}
                                <Grid item xs={12}>
                                    <SuiButton 
                                      style={{ float: "right" }} 
                                      type="submit" 
                                      buttonColor={isNewItem ? "primary" : "secondary"}
                                    >
                                        {isNewItem ? "Save" : "Update"}
                                    </SuiButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Card>
            </Box>
        </Box >
    );
}

DiscountForm.defaultProps = {
    isNewItem: true,
};

DiscountForm.propTypes = {
    isNewItem: PropTypes.bool,
    control: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
};

export default DiscountForm;