import { Route } from 'react-router-dom';
import { pages } from "links/pages";
import VendorSliderList from './view/list';
import VendorSliderAdd from './view/create';
import VendorSliderUpdate from './view/update';

export default function VendorSliderController() {
    return (
        <>
            <Route exact path={pages.VENDOR_SLIDER_LIST + "/vendors/:id"} component={VendorSliderList} />
            <Route exact path={pages.VENDOR_SLIDER_UPDATE +"/:id"} component={VendorSliderUpdate} />
            <Route exact path={pages.VENDOR_SLIDER_ADD} component={VendorSliderAdd} />
        </>
    );
}