
// Soft UI Dashboard React example components
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { pages } from "links/pages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import ProfileForm from "./_form";
// Custom styles for the Tables

// Service
import { updateProfile } from "../service"
import tokenService from "_services/token.service";

const schema = yup.object({
    image: yup.mixed().test("fileSize", "Max file size is 3MB", (value, ob1) => {
        return !value || value[0].size < 3150000;
    }).test("type", "Only jpg,jpeg, webp and png are supported", (value) => {
        return !value || (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/webp" || value[0].type === "image/png");
    }),
    email: yup.string().email(),
    password: yup.string().when([], {
        is: (val) => val && val.length > 0,
        then: yup.string().min(8, 'Password must be at least 8 characters'),
        otherwise: yup.string(),
    }),
    name: yup.string().required("Name cannot be blank").max(100),
    address: yup.string().max(100),
    phone: yup.string().max(10),
    gender: yup.string().oneOf(["male", "female", "other"]),
});

function ProfileUpdate() {
    const { enqueueSnackbar } = useSnackbar();
    const [profileData, setProfile] = useState(tokenService.getUser());
    const history = useHistory();
    async function loadData() {
        const itData = tokenService.getUser();
        reset({
            email: itData.email,
            password: "",
            name: itData.name,
            address: itData.address,
            image: null,
            img: itData.photo_link,
            gender: itData.gender,
            phone: itData.phone
        }, {
            keepDirty: true
        });
    }
    useEffect(() => {
        loadData();
    }, []);

    const { handleSubmit, control, getValues, reset } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        keepDirty: true,
        defaultValues: useMemo(() => {
            return {
                ...profileData
            };
        }, [profileData])
    });

    async function onSubmitHandler(data) {
        await updateProfile(profileData.id, data).then((res) => {
            if (res.flag) {
                enqueueSnackbar("Profile update success", {
                    variant: 'success',
                });
                setProfile(pre => {
                    tokenService.updateUser(res.data.data.admin)
                    return res.data.data.admin;
                });
                history.push(pages.PROFILE_VIEW);
            } else {
                enqueueSnackbar("Profile update failed", {
                    variant: 'error',
                });
            }
        });
    };
    return (
        <ProfileForm
            onSubmitHandler={onSubmitHandler}
            handleSubmit={handleSubmit}
            control={control}
            isNewCustomer={false}
            getValues={getValues}
        />
    );
}

export default ProfileUpdate;
