import { getApiRequest as getApi, getJsonForm } from '_services';


const getApiRequest = async (requestData) => {
    const result = await getApi({
        ...requestData
    }).then(function (response) {
        return { flag: true, headers: response.headers, status: response.status, message: "Success", data: response.data };
    }).then(function (finalJson) {
        return finalJson;
    }).catch((error) => {
        return { flag: false, status: error.status, message: "Error", data: error.data };
    });
    return result;
}

async function getProfileDetail(id) {
    return await getApiRequest({
        url: "/single/" + id
    }).then((result) => {
        return result;
    });
}


async function updateProfile(id, idata) {
    console.log(idata)
    if (!Boolean(idata["image"])) {
        delete idata["image"];
    } else {
        idata["photo"] = idata["image"];
        delete idata["image"];
    }
    if (!Boolean(idata["password"])) {
        delete idata["password"];
    }
    delete idata["img"];
    console.log(idata)
    const fdata = getJsonForm(idata);
    return await getApiRequest({
        url: "/update/" + id,
        method: "patch",
        data: fdata,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((result) => {
        return result;
    });
}

export {
    getProfileDetail,
    updateProfile,
};