import { 
    getApiRequest as getApi,
    getApiDeleteRequest,
    getApiPatchRequest
} from '_services';

const getApiRequest = async (requestData) => {
    const result = await getApi({
        ...requestData
    }).then(function (response) {
        return { flag: true, headers: response.headers, status: response.status, message: "Success", data: response.data };
    }).then(function (finalJson) {
        return finalJson;
    }).catch((error) => {
        return { flag: false, status: error.status, message: "Error", data: error.data };
    });
    return result;
}


async function getVendorSliderList(param) {
    return await getApiRequest({
        url: "/slider",
        data: {
            ...param
        }
    }).then((result) => {
        return result;
    });
}

async function getVendorSliderDetail(id) {
    return await getApiRequest({
        url: "/slider/single/" + id
    }).then((result) => {
        return result;
       
    });
}

async function getVendorSliderDelete(id) {
    return await getApiDeleteRequest("/slider/delete/" + id).then((result) => {
        return result;
    });
}


async function addVendorSlider(idata) {
    return await getApiRequest({
        url: "/slider/store",
        method: "post",
        data: idata
    }).then((result) => {
        return result;
    });
}

async function updateVendorSlider(id, idata) {
    return await getApiPatchRequest("/slider/update/" + id, idata).then((result) => {
        return result;
    });
}

export {
    getVendorSliderList,
    getVendorSliderDetail,
    addVendorSlider,
    updateVendorSlider,
    getVendorSliderDelete
};