import { Suspense } from 'react';
import { Route } from 'react-router-dom';
import Methods from "_base/Methods";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import routes from "links/routes";
import Sidenav from "layouts/Sidenav";
import ProgressBar from "components/ProgressBar";
import DiscountController from './discounts';
import VendorSliderController from './vendor-sliders';
import ProfileController from './profile';


function MainController() {
    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                return <Route path={route.route} component={route.component} key={route.key} />;
            }
            return null;
        });
    return (
        <div key="MainController">
            <Sidenav key="Sidenav" />
            <DashboardLayout key="DashboardLayout">
                <DashboardNavbar key="DashboardNavbar" isMini={false} absolute={false} light={true} />
                <Suspense fallback={
                    <ProgressBar />
                }>
                    {getRoutes(routes)}
                    { /* for discounts */}
                    {DiscountController()}
                    { /* for vendor gallery */}
                    {VendorSliderController()}
                    { /* for profile */}
                    {ProfileController()}
                    { /* for logout */}
                    <Route exact path={"/logout"} component={() => <Methods logout={true} />} />
                </Suspense>
            </DashboardLayout>
        </div >
    );
}

export default MainController;