import React from "react";
// Soft UI Dashboard React layouts
import { Dashboard } from "view/main/dashboard";
// import Methods from "_base/Methods";
import { pages } from "./pages";

// Soft UI Dashboard React icons
// import TurnedInIcon from "@mui/icons-material/TurnedIn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
// import InventoryIcon from "@mui/icons-material/Inventory";
import CategoryIcon from "@mui/icons-material/Category";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import StoreIcon from "@mui/icons-material/Store";
import ArticleIcon from "@mui/icons-material/Article";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import CollectionsIcon from "@mui/icons-material/Collections";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import HelpIcon from "@mui/icons-material/Help";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import ReviewsIcon from "@mui/icons-material/Reviews";

// const ItemController = React.lazy(() => import("view/main/items"));
const CustomerController = React.lazy(() => import("view/main/customer"));

// const PurchaseController = React.lazy(() => import("view/main/purchase"));
const CategoryController = React.lazy(() => import("view/main/categories"));
const SliderController = React.lazy(() => import("view/main/sliders"));
const VendorController = React.lazy(() => import("view/main/vendors"));
const PageController = React.lazy(() => import("view/main/pages"));
const VariationController = React.lazy(() => import("view/main/variations"));
const SubscribeController = React.lazy(() => import("view/main/subscribe"));
const VariationAttributeController = React.lazy(() =>
  import("view/main/variation-attributes")
);
const ProductController = React.lazy(() => import("view/main/products"));
const GalleryController = React.lazy(() => import("view/main/galleries"));
const FaqController = React.lazy(() => import("view/main/faqs"));
const InquiryController = React.lazy(() => import("view/main/inquiries"));
const SalesController = React.lazy(() => import("view/main/sales"));
const VoucherController = React.lazy(() => import("view/main/vouchers"));
const SettingsController = React.lazy(() => import("view/main/settings"));
const ReviewController = React.lazy(() => import("view/main/reviews"));

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    rid: "dashboard",
    icon: <DashboardIcon size="12px" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sales",
    key: "sales",
    route: pages.SALES_LIST,
    icon: <ShoppingBagIcon size="12px" />,
    component: SalesController,
    noCollapse: true,
    rid: "sales",
  },
  {
    type: "collapse",
    name: "Sliders",
    key: "sliders",
    route: pages.SLIDER_LIST,
    icon: <ViewCarouselIcon size="12px" />,
    component: SliderController,
    noCollapse: true,
    rid: "sliders",
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    route: pages.CATEGORY_LIST,
    icon: <CategoryIcon size="12px" />,
    component: CategoryController,
    noCollapse: true,
    rid: "categories",
  },
  {
    type: "collapse",
    name: "Variations",
    key: "variations",
    route: pages.VARIATION_LIST,
    icon: <ViewWeekIcon size="12px" />,
    component: VariationController,
    noCollapse: true,
    rid: "variations",
  },
  {
    type: "collapse",
    name: "Variations Attributes",
    key: "variation-attributes",
    route: pages.VARIATION_ATTRIBUTE_LIST,
    icon: <ViewModuleIcon size="12px" />,
    component: VariationAttributeController,
    noCollapse: true,
    rid: "variation-attributes",
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    route: pages.PRODUCT_LIST,
    icon: <ShoppingBagIcon size="12px" />,
    component: ProductController,
    noCollapse: true,
    rid: "products",
  },
  {
    type: "collapse",
    name: "Gallery",
    key: "galleries",
    route: pages.GALLERY_LIST,
    icon: <CollectionsIcon size="12px" />,
    component: GalleryController,
    noCollapse: true,
    rid: "galleries",
  },
  {
    type: "collapse",
    name: "Vendors",
    key: "vendors",
    route: pages.VENDOR_LIST,
    icon: <StoreIcon size="12px" />,
    component: VendorController,
    noCollapse: true,
    rid: "vendors",
  },
  {
    type: "collapse",
    name: "Customer",
    key: "customer",
    route: pages.CUSTOMER_LIST,
    icon: <PeopleIcon size="12px" />,
    component: CustomerController,
    noCollapse: true,
    rid: "customer",
  },
  {
    type: "collapse",
    name: "Vouchers",
    key: "vouchers",
    route: pages.VOUCHER_LIST,
    icon: <ReceiptIcon size="12px" />,
    component: VoucherController,
    noCollapse: true,
    rid: "vouchers",
  },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    route: pages.PAGE_LIST,
    icon: <ArticleIcon size="12px" />,
    component: PageController,
    noCollapse: true,
    rid: "pages",
  },
  {
    type: "collapse",
    name: "FAQs",
    key: "faqs",
    route: pages.FAQ_LIST,
    icon: <HelpIcon size="12px" />,
    component: FaqController,
    noCollapse: true,
    rid: "faqs",
  },
  {
    type: "collapse",
    name: "Inquiries",
    key: "inquiries",
    route: pages.INQUIRY_LIST,
    icon: <MessageIcon size="12px" />,
    component: InquiryController,
    noCollapse: true,
    rid: "inquiries",
  },

  {
    type: "collapse",
    name: "Reviews",
    key: "review",
    route: pages.REVIEW_LIST,
    icon: <ReviewsIcon size="12px" />,
    component: ReviewController,
    noCollapse: true,
    rid: "review",
  },

  {
    type: "collapse",
    name: "Subscriber",
    key: "subscriber",
    route: pages.SUBSCRIBE_LIST,
    icon: <EmailIcon size="12px" />,
    component: SubscribeController,
    noCollapse: true,
    rid: "subscriber",
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: pages.SETTINGS_VIEW,
    icon: <SettingsIcon size="12px" />,
    component: SettingsController,
    noCollapse: true,
    rid: "settings",
  },

  // {
  //   type: "collapse",
  //   name: "Purchase",
  //   key: "purchase",
  //   route: pages.PURCHASE_LIST,
  //   icon: <InventoryIcon size="12px" />,
  //   component: PurchaseController,
  //   noCollapse: true,
  //   rid: "purchase",
  // },
  // {
  //   type: "collapse",
  //   name: "Items",
  //   key: "items",
  //   route: pages.ITEM_LIST,
  //   icon: <TurnedInIcon size="12px" />,
  //   component: ItemController,
  //   noCollapse: true,
  //   rid: "items",
  // },
  // {
  //   type: "collapse",
  //   name: "Customer",
  //   key: "customer",
  //   route: pages.CUSTOMER_LIST,
  //   icon: <PeopleIcon size="12px" />,
  //   component: CustomerController,
  //   noCollapse: true,
  //   rid: "customer",
  // },
  // {
  //   type: "collapse",
  //   name: "Logout",
  //   key: "sign-out",
  //   route: "/logout",
  //   icon: <LogoutIcon size="12px" />,
  //   component: () => <Methods logout={true} />,
  //   noCollapse: false,
  // },
];

export default routes;
