import React from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { pages, guestPage } from "links/pages";
import tokenService from '_services/token.service';

const Auth = ({ children }) => {
    const userModel = tokenService.getUser();
    const location = useLocation();
    const history = useHistory();
    function checkLogin() {
        if (userModel === null) {
            if (!guestPage.includes(location.pathname)) {
                history.push(pages.LOGIN);
            }
        } else {
            if (guestPage.includes(location.pathname)) {
                history.push(pages.DASHBOARD);
            }
        }
        return (
            <div key="AuthController">
                {children}
            </div>
        );
    }
    return checkLogin();
}

export default Auth;