// @mui material components
import Card from "@mui/material/Card";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
// Soft UI Dashboard React components
import Box from "@mui/material/Box";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard React example components
import Table from "layouts/Table";
import { sliderPage, sliderTitle } from "links";
import { Link } from 'react-router-dom';
// Custom styles for the Tables
import styles from "./styles";

// Data
import { columns, modelList, modelListInit, modelListEmpty, modelPages } from "../model/list";
import { getVendorSliderDelete, getVendorSliderList } from "../service";;
import { useDispatch, useSelector } from "react-redux";
import { setVendorSliderList } from "redux/action/vendorSliderAction";
import { useSnackbar } from "notistack";
import { deleteAlert } from "_services";

// main component
export default function VendorSliderList() {
    const { enqueueSnackbar } = useSnackbar()
    const classes = styles();
    const history = useHistory();
    const { sliders, pg } = useSelector(state => state.sliderList);
    const dispatch = useDispatch();
    const handleView = (id, name) => {
        history.push({
            pathname: sliderPage.SLIDER_VIEW,
            search: "?" + (name.replace(" ", "-").toLowerCase()),
            state: {
                id,
                name
            }
        });
    }
    const handleClick = (_, current) => {
        if (pg.current != current) {
            loadData(current);
        }
    }

    const handleDelete = async (id) => {
        const deleteHandler = () => getVendorSliderDelete(id);
        deleteAlert({ title: "Are you sure?"}, deleteHandler)
        .then((res) => {
            if (res === null) {
                return;
            }
            if (res.status === 200) {
                enqueueSnackbar("VendorSlider delete success", {
                    variant: 'success',
                });
                loadData(1);
            } else {
                enqueueSnackbar("VendorSlider delete failed", {
                    variant: 'error',
                });
            }
        });

    }

    async function loadData(page) {
        await getVendorSliderList({ page }).then((res) => {
            if (res.flag) {
                if (Object.keys(res.data).length) {
                    dispatch(setVendorSliderList({
                        sliders: res.data.data,
                        pg: {
                            size: Number(res.data?.size),
                            pages: Number(res.data?.lastPage),
                            current: Number(res.data?.currentPage),
                            total: Number(res.data?.total),
                        }
                    }));
                } else {
                    dispatch(setVendorSliderList({
                        sliders: [],
                        pg: {
                            size: 0,
                            pages: 0,
                            current: 0,
                            total: 0,
                        }
                    }));
                }
            }

        });
    }
    useEffect(() => {
        loadData(1);
        return () => {
            dispatch(setVendorSliderList({ sliders: [], pg: {} }));
        }
    }, []);

    const TableRender = () => {
        if (sliders === null) {
            return (
                <div>
                    <Table columns={columns} rows={modelListInit()} />
                </div>
            );
        } else if (sliders.length == 0) {
            return (
                <div>
                    <Table columns={columns} rows={modelListEmpty()} />
                </div>
            );
        } else {
            return (
                <div>
                    <Table columns={columns} pagination={pg} rows={modelList(sliders, handleView, handleDelete, history)} />
                    <Box mt={1} mb={2} style={{ float: "right" }}>
                        {modelPages(pg, handleClick)}
                    </Box>
                </div>
            );
        }
    }

    return (
        <Box py={3}>
            <Box mb={3}>
                <Card>
                    <Box display="flex" justifyContent="space-between" alignsliders="center" p={3}>
                        <SuiTypography variant="h6">{sliderTitle.SLIDER_LIST}</SuiTypography>
                        <Link to={sliderPage.SLIDER_ADD}>
                            <SuiButton buttonColor="secondary">Add VendorSlider</SuiButton>
                        </Link>
                    </Box>
                    <SuiBox customClass={classes.tables_table}>
                        <TableRender />
                    </SuiBox>
                </Card>
            </Box>
        </Box>
    );
}
